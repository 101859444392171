<script>
import { controlTableCultureStatusesList } from '@/views/control/components/tableCulture/data/ControlTableCulture'

export default {
  name: 'TableCell',
  props: {
    item: {
      type: [String, Array, Number],
      default: '',
    },
    row: {
      type: Object,
      default: () => {},
    },
    propKey: {
      type: String,
      default: '',
    },
    status: {
      type: [String, Number],
      default: '',
    },
  },
  methods: {
    getStatus(status) {
      return controlTableCultureStatusesList[status]
        ? controlTableCultureStatusesList[status]
        : controlTableCultureStatusesList.undefinedStatus
    },
    createTableCellStatus(createElement) {
      const { status } = this.row
      const content = this.getStatus(status)

      return [
        createElement('div', { class: [`culture-status-${status}`, 'badge'] }, [
          content,
        ]),
      ]
    },
    createTableCellAllOption(createElement) {
      const { name, status } = this.row
      const statusCulture = this.getStatus(status)
      const content = [
        name,
        {
          content: statusCulture,
          classes: [`culture-status-${status}`, 'badge'],
        },
      ]

      return content.map((item, i) => {
        if (item) {
          return createElement(
            'div',
            {
              class: [
                `allOption${i}`,
                item?.classes?.length > 0 ? item.classes : '',
              ],
            },
            [item?.content ? item.content : item],
          )
        }
      })
    },
  },
  render(createElement) {
    let classNames = [this.propKey]
    let renderData = [this.item]

    if (Array.isArray(this.item)) {
      renderData = this.item.map(item => createElement('div', item))
    }
    switch (this.propKey) {
      case 'status': {
        renderData = this.createTableCellStatus(createElement)
        break
      }

      case 'allOption': {
        renderData = this.createTableCellAllOption(createElement)
        classNames.push('ai-fs')
        break
      }
    }

    return createElement('div', { class: classNames }, renderData)
  },
}
</script>
