import {
  controlTableAddItem,
  controlTableRemoveSelectedItems,
} from '@/views/control/data/control'

export const controlTableStep = [
  {
    prop: 'name',
    label: 'Наименование',
    width: '320',
  },
  {
    prop: 'harvest_year',
    label: 'Год сбора урожая',
  },
]
export const controlTableCultureFieldsDesktop = [
  {
    prop: 'name',
    label: 'Наименование',
    width: '320',
  },
  {
    prop: 'harvest_year',
    label: 'Год сбора урожая',
  },

  // { prop: 'short_name', label: 'Короткое название' },
  // {
  //   prop: 'status',
  //   label: 'Статус',
  //   width: '135',
  //   headerIcon: true,
  //   sortKey: 'status',
  // },
]
export const controlTableCultureFieldsTablet = [
  {
    prop: 'name',
    label: 'Наименование',
  },

  // {
  //   prop: 'status',
  //   label: 'Статус',
  //   width: '135',
  //   headerIcon: true,
  //   sortKey: 'status',
  // },
]

export const controlTableCultureFieldsMobile = [
  // {
  //   prop: 'allOption',
  //   label: ['Наименование', 'Статус'],
  // },
  {
    prop: 'name',
    label: 'Наименование',
  },
]

export const controlTableCultureStatusesList = {
  0: 'не активный',
  1: 'Активный',
  undefinedStatus: 'неопределено',
}

export const controlTableCultureDropdownMenuList = [
  {
    id: 1,
    label: 'Удалить выбранные',
    value: controlTableRemoveSelectedItems,
    class: 'm-b-12',
  },
  {
    id: 2,
    label: 'Добавить культуру',
    value: controlTableAddItem,
    isDivided: true,
  },
]
